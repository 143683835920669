import React, { useState, useEffect } from 'react';

const InstallPWAButton = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showInstallButton, setShowInstallButton] = useState(false);

    const beforeInstallPrompt = (event) => {
        // Prevent the default browser install prompt.
        // console.log('we are being triggered :D');
        event.preventDefault();
        setDeferredPrompt(event);
        setShowInstallButton(true);
    };

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
        // Clean up the event listener when the component unmounts.
        return () => {
            window.removeEventListener('transitionend', beforeInstallPrompt);
        };
    }, []);

    const installClick = (e) => {
        // console.log('we are being clicked :D');
        e.preventDefault();
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the installation');
                }
                setDeferredPrompt(null);
                setShowInstallButton(false);
            });
        } else {
            console.log('we have no deferredPrompt :D');
        }
    };
    const buttonStyles = {
        // display: 'block',
        width: '25%',
        backgroundColor: 'rgba(48, 121, 95, 0.83)', /* Background color */
        color: '#fff', /* Text color */
        padding: '0.4rem 0', /* Adjust padding for your button size */
        textAlign: 'center',
        fontSize: '1.3rem', /* Adjust the font size as needed */
        border: 'none',
        cursor: 'pointer',
        position: 'fixed',
        top: '0',
        zIndex: '1029', /* Ensure the button is above other elements */
        borderRadius: '0 0 10px 10px', /* Rounded corners at the bottom */
    };

    return (
        <>
            {showInstallButton && (
                <div style={{ textAlign: 'center', width: '100%' }}>
                    <button id="install-button" onClick={installClick} className="m-header" style={buttonStyles}>
                        Instalar
                    </button>
                </div>
            )}
        </>
    );
};

export default InstallPWAButton;
