import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthGuard = ({ children }) => {
    const { isLoggedIn, token } = useSelector((state) => state.account);
    console.log('AuthGuard', isLoggedIn, token);

    if (!isLoggedIn) {
        return <Redirect to="/auth/signin" />;
    }

    return children;
};

export default AuthGuard;
