import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';
import ChatLayout from './layouts/ChatLayout';
import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';
import { BASE_URL } from './config/constant';
import useHasPermission from './hooks/useHasPermission';
import * as permissions from './config/permissions';
import PermissionDeniedLayout from './layouts/PermissionDeniedLayout';

export const renderRoutes = (routes = []) => {
    const renderLayout = (hasPermission, route) => {
        const Layout = route.layout || Fragment;

        if (!hasPermission) {
            return <PermissionDeniedLayout />;
        }

        if (route.routes) {
            return <Layout>{renderRoutes(route.routes)}</Layout>;
        }

        return <Layout><route.component /></Layout>;
    };

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {routes.map((route, index) => {
                    const Guard = route.guard || Fragment;
                    const hasPermission = useHasPermission(route.permissions);

                    return (
                        <Route
                            key={`route-key-${index}`}
                            path={route.path}
                            exact={route.exact}
                        >
                            <Guard>
                                {renderLayout(hasPermission, route)}
                            </Guard>
                        </Route>
                    );
                })}
            </Switch>
        </Suspense>
    );
};

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        component: lazy(() => import('./views/auth/signin/SignIn')),
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signup',
        component: lazy(() => import('./views/auth/signup/SignUp')),
    },
    {
        exact: true,
        layout: AdminLayout,
        guard: AuthGuard,
        path: '/auth/logout',
        component: lazy(() => import('./views/auth/logout')),
    },
    {
        exact: true,
        layout: AdminLayout,
        guard: AuthGuard,
        path: '/home',
        component: lazy(() => import('./views/dashboard/Home')),
    },
    {
        exact: true,
        layout: AdminLayout,
        guard: AuthGuard,
        path: '/settings',
        component: lazy(() => import('./views/settings')),
        permissions: [permissions.MAIN_SETTINGS.READ],
    },
    {
        exact: true,
        layout: AdminLayout,
        guard: AuthGuard,
        path: '/roles',
        component: lazy(() => import('./views/roles')),
        permissions: [permissions.ROLE_PERMISSIONS.READ],
    },
    {
        path: '/org',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/org/:orgId([0-9a-fA-F]{24})/groups',
                component: lazy(() => import('./views/metadata_clients/index')),
                permissions: [permissions.OWN_ORGANIZATION.READ],
            },
        ],
    },
    {
        path: '/orgs',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/orgs/',
                component: lazy(() => import('./views/org')),
                permissions: [permissions.OWN_ORGANIZATION.READ],
            },
            {
                exact: true,
                path: '/orgs/:orgId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/org/form')),
                permissions: [permissions.OWN_ORGANIZATION.READ],
            },
            {
                exact: true,
                path: '/orgs/:orgId([0-9a-fA-F]{24})/invite',
                component: lazy(() => import('./views/org/InviteUser')),
                permissions: [permissions.OWN_ORGANIZATION.UPDATE],
            },
            {
                exact: true,
                path: '/orgs/:orgId([0-9a-fA-F]{24})/members/:userId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/org/EditMemberForm')),
                permissions: [permissions.OWN_ORGANIZATION.UPDATE],
            },
            {
                exact: true,
                path: '/orgs/:orgId([0-9a-fA-F]{24})/members/:userId([0-9a-fA-F]{24})/remove',
                component: lazy(() => import('./views/org/RemoveMember')),
                permissions: [permissions.OWN_ORGANIZATION.UPDATE],
            },
            {
                exact: true,
                path: '/orgs/add/',
                component: lazy(() => import('./views/org/form')),
                permissions: [permissions.OWN_ORGANIZATION.CREATE],
            },
            {
                exact: true,
                path: '/orgs/:orgId([0-9a-fA-F]{24})/invites/:inviteId([0-9a-fA-F]{24})/revoke',
                component: lazy(() => import('./views/org/RevokeInvite')),
                permissions: [permissions.OWN_ORGANIZATION.UPDATE],
            },
        ],
    },
    {
        path: '/users',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/users/',
                component: lazy(() => import('./views/users')),
                permissions: [permissions.ORG_USERS.READ],
            },
            {
                exact: true,
                path: '/users/:userId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/users/form')),
                permissions: [permissions.ORG_USERS.READ],

            },
            {
                exact: true,
                path: '/users/add/',
                component: lazy(() => import('./views/users/form')),
                permissions: [permissions.ORG_USERS.READ],

            },
        ],
    },
    {
        exact: true,
        path: '/icons',
        layout: AdminLayout,
        guard: AuthGuard,
        component: lazy(() => import('./views/extra/Icons')),
    },
    {
        path: '/accounts',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            /* Whatsapp */
            {
                exact: true,
                path: '/accounts/whatsapp',
                component: lazy(() => import('./views/accounts/whatsapp')),
                permissions: [permissions.WHATSAPP_ACCOUNTS.READ],
            },
            {
                exact: true,
                path: '/accounts/whatsapp/add',
                component: lazy(() => import('./views/accounts/whatsapp/Form')),
                permissions: [permissions.WHATSAPP_ACCOUNTS.CREATE],
            },
            {
                exact: true,
                path: '/accounts/whatsapp/edit',
                component: lazy(() => import('./views/accounts/whatsapp/Form')),
                permissions: [permissions.WHATSAPP_ACCOUNTS.UPDATE],
            },
            {
                exact: true,
                path: '/accounts/whatsapp/:waId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/accounts/whatsapp/Form')),
                permissions: [permissions.WHATSAPP_ACCOUNTS.UPDATE],
            },
            /* Telegram */
            {
                exact: true,
                path: '/accounts/telegram',
                component: lazy(() => import('./views/accounts/telegram')),
                permissions: [permissions.TELEGRAM_ACCOUNTS.READ],
            },
            {
                exact: true,
                path: '/accounts/telegram/add',
                component: lazy(() => import('./views/accounts/telegram/Form')),
                permissions: [permissions.TELEGRAM_ACCOUNTS.CREATE],
            },
            {
                exact: true,
                path: '/accounts/telegram/:tgId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/accounts/telegram/Form')),
                permissions: [permissions.TELEGRAM_ACCOUNTS.UPDATE],
            },
            /* Webchat */
            {
                exact: true,
                path: '/accounts/webchat',
                component: lazy(() => import('./views/accounts/webchat')),
                permissions: [permissions.WEBCHAT_ACCOUNTS.READ],
            },
            {
                exact: true,
                path: '/accounts/webchat/create',
                component: lazy(() => import('./views/accounts/webchat/Form')),
                permissions: [permissions.WEBCHAT_ACCOUNTS.CREATE],
            },
            {
                exact: true,
                path: '/accounts/webchat/:wcId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/accounts/webchat/Form')),
                permissions: [permissions.WEBCHAT_ACCOUNTS.UPDATE],
            },
        ],
    },
    {
        path: '/integrations',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/integrations/rasa/create',
                component: lazy(() => import('./views/integrations/rasa/Form')),
                permissions: [permissions.RASA_INTEGRATIONS.CREATE],
            },
            {
                exact: true,
                path: '/integrations/rasa/:rasaId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/integrations/rasa/Form')),
                permissions: [permissions.RASA_INTEGRATIONS.UPDATE],
            },
            {
                exact: true,
                path: '/integrations/rasa',
                component: lazy(() => import('./views/integrations/rasa')),
                permissions: [permissions.RASA_INTEGRATIONS.READ],
            },
            {
                exact: true,
                path: '/integrations/langflow/create',
                component: lazy(() => import('./views/integrations/langflow/Form')),
                permissions: [permissions.LANGFLOW_INTEGRATIONS.CREATE],
            },
            {
                exact: true,
                path: '/integrations/langflow/:langflowId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/integrations/langflow/Form')),
                permissions: [permissions.LANGFLOW_INTEGRATIONS.UPDATE],
            },
            {
                exact: true,
                path: '/integrations/langflow',
                component: lazy(() => import('./views/integrations/langflow')),
                permissions: [permissions.LANGFLOW_INTEGRATIONS.READ],
            },
            {
                exact: true,
                path: '/integrations/n8n/create',
                component: lazy(() => import('./views/integrations/n8n/Form')),
                permissions: [permissions.N8N_INTEGRATIONS.CREATE],
            },
            {
                exact: true,
                path: '/integrations/n8n/:n8nId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/integrations/n8n/Form')),
                permissions: [permissions.N8N_INTEGRATIONS.UPDATE],
            },
            {
                exact: true,
                path: '/integrations/n8n',
                component: lazy(() => import('./views/integrations/n8n')),
                permissions: [permissions.N8N_INTEGRATIONS.READ],
            },
        ],
    },
    {
        path: '/contacts',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/contacts',
                component: lazy(() => import('./views/campaigns/contacts')),
                permissions: [permissions.CONTACT_LISTS.READ],
            },
            {
                exact: true,
                path: '/contacts/:listId([0-9a-fA-F]{24})',
                component: lazy(() => import('./views/campaigns/contacts')),
                permissions: [permissions.CONTACT_LISTS.READ],
            },
            {
                exact: true,
                path: '/contacts/:contactId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/campaigns/contacts/Form')),
                permissions: [permissions.CONTACT_LISTS.UPDATE],
            },
            {
                exact: true,
                path: '/contacts/add',
                component: lazy(() => import('./views/campaigns/contacts/Form')),
                permissions: [permissions.CONTACT_LISTS.CREATE],
            },
            {
                exact: true,
                path: '/contacts/:listId([0-9a-fA-F]{24})/add',
                component: lazy(() => import('./views/campaigns/contacts/Form')),
                permissions: [permissions.CONTACT_LISTS.CREATE],
            },
        ],
    },
    {
        path: '/groups',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/groups/add',
                component: lazy(() => import('./views/campaigns/groups/Form')),
                permissions: [permissions.CONTACT_LISTS.CREATE],
            },
            {
                exact: true,
                path: '/groups/:contactListId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/campaigns/groups/Form')),
                permissions: [permissions.CONTACT_LISTS.UPDATE],
            },
            {
                exact: true,
                path: '/groups',
                component: lazy(() => import('./views/campaigns/groups')),
                permissions: [permissions.CONTACT_LISTS.READ],
            },
        ],
    },
    {
        path: '/campaigns',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/campaigns/add',
                component: lazy(() => import('./views/campaigns/messages/Form')),
                permissions: [permissions.MESSAGE_CAMPAIGNS.CREATE],
            },
            {
                exact: true,
                path: '/campaigns/:campaignId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/campaigns/messages/Form')),
                permissions: [permissions.MESSAGE_CAMPAIGNS.UPDATE],
            },
            {
                exact: true,
                path: '/campaigns',
                component: lazy(() => import('./views/campaigns/messages')),
                permissions: [permissions.MESSAGE_CAMPAIGNS.READ],
            },
        ],

    },
    {
        path: '/call_indicators',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/call_indicators/:attendanceId([0-9a-fA-F]{24})/:roomId([0-9a-fA-F]{24})/',
                component: lazy(() => import('./views/emater_api/call_indicators/index')),
                permissions: [permissions.CONTACT_LISTS.READ],
            },
        ],
    },
    {
        path: '/logs',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/logs/view',
                component: lazy(() => import('./views/audit/index')),
                permissions: [permissions.AUDITING.READ],
            },

        ],
    },
    {
        path: '/reports',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/reports/view',
                component: lazy(() => import('./views/reports/index')),
            },

        ],
    },
    {
        path: '/visitor_metadata',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/visitor_metadata/upsert',
                component: lazy(() => import('./views/metadata_visitor/index')),
                permissions: [permissions.VISITOR_METADATA.READ],
            },

        ],
    },
    {
        path: '/attendance',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/attendance/add/',
                component: lazy(() => import('./views/attendance/attendance_form_steps')),
                permissions: [permissions.ATTENDANCES.CREATE],
            },
            {
                exact: true,
                path: '/attendance/:attendanceId([0-9a-fA-F]{24})/',
                component: lazy(() => import('./views/attendance/panel')),
                permissions: [permissions.ATTENDANCES.READ],
            },
            {
                exact: true,
                path: '/attendance/:attendanceId([0-9a-fA-F]{24})/edit',
                component: lazy(() => import('./views/attendance/attendance_form_steps')),
                permissions: [permissions.ATTENDANCES.UPDATE],
            },
            {
                exact: true,
                path: '/attendance/:attendanceId([0-9a-fA-F]{24})/deactivate',
                component: lazy(() => import('./views/attendance/panel')),
                permissions: [permissions.ATTENDANCES.UPDATE],
            },
            // {
            //     exact: true,
            //     path: '/attendance/:attendanceId([0-9a-fA-F]{24})/department/:departmentId([0-9a-fA-F]{24})/workflow',
            //     component: lazy(() => import('./views/attendance/advanced_chat/departments/WorkflowDepartment')),
            //     permissions: [permissions.ATTENDANCES_DEPARTMENTS.UPDATE],
            // },
            {
                exact: true,
                path: '/attendance/:attendanceId([0-9a-fA-F]{24})/call_appointments',
                component: lazy(() => import('./views/attendance/call_appointments')),
                permissions: [permissions.ATTENDANCES.READ],
            },
        ],
    },
    {
        path: '/advanced-chat',
        guard: AuthGuard,
        routes: [
            {
                exact: true,
                path: '/advanced-chat/:attendanceId([0-9a-fA-F]{24})/',
                component: lazy(() => import('./views/attendance/advanced_chat')),
            },
            {
                exact: true,
                path: '/advanced-chat/:attendanceId([0-9a-fA-F]{24})/room/:roomId([0-9a-fA-F]{24})/',
                component: lazy(() => import('./views/attendance/advanced_chat')),
            },
        ],
    },
    {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
    },
];

export default routes;
