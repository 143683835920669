/* eslint-disable no-restricted-globals  */
/* eslint-disable no-unused-vars  */
/* eslint-disable prefer-regex-literals  */
/* eslint-disable operator-linebreak  */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store, persister } from './store';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <PersistGate loading={null} persistor={persister}>
                <App />
            </PersistGate>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root'),
);

serviceWorkerRegistration.register();
reportWebVitals();
