import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API_SERVER } from '../config/constant';


const axiosServices = axios.create();

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services'),
);

export default axiosServices;

export const InjectAxiosInterceptors = () => {
    const navHistory = useHistory();
    const account = useSelector((state) => state.account);
    axios.defaults.baseURL = API_SERVER;
    axios.defaults.disableClientErrorHandling = true;
    axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${account?.user?.access_token}`,
    };

    addInterceptors(navHistory);

    return null;
};

export const addInterceptors = (history) => {
    axios.interceptors.response.use((response) => response, (error) => Promise.reject(axiosErrorHandling(error, history)));
};

export const axiosErrorHandling = (error, history) => {
    const statusCode = error?.response?.status;
    
    if (statusCode) {
        if (!error?.config?.disableAuthErrorHandling && statusCode === 401) {
        // if (statusCode === 401) {
            console.log('axios statusCode history push', statusCode);
            history.push('/auth/logout');
            // window.location.href = '/auth/logout';

        } else if (statusCode === 403) {
            toast.error('Usuário sem permissão!');
        // Handle 4xx errors if error handling enabled
        } else if (!error?.config?.disableClientErrorHandling && statusCode >= 400 && statusCode < 500 && statusCode !== 401) {
            const errorMessages = [];
            const errorDetails = error.response.data?.detail;
            if (errorDetails) {
                if (Array.isArray(errorDetails)) {
                    errorDetails.forEach((errorDetail) => {
                        errorMessages.push(`${errorDetail.loc[1]}: ${errorDetail.msg}`);
                    });
                } else if (typeof errorDetails === 'string') {
                    errorMessages.push(error.response.data.detail);
                }
            }
            if (errorMessages.length === 0) {
                if (error?.response?.statusText) {
                    errorMessages.push(error?.response?.statusText);
                } else {
                    errorMessages.push('Algo deu errado!');
                }
            }
            errorMessages.forEach((errorMessage) => toast.error(errorMessage));
            // Handle Server errors
        } else if (statusCode >= 500) {
            toast.error('Internal server error!');
        }
    // Handle server errors if there is no response
    } else if (error?.message) {
        toast.error(error.message);
    // Default error message
    } else {
        toast.error('Algo deu errado!');
    }

    return error;
};
