export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/home';
export const BASE_TITLE = ' | Social ';
export const API_SERVER = process.env.REACT_APP_API_SERVER || 'http://localhost:8000';
export const WS_SERVER = process.env.REACT_APP_WS_SERVER || 'ws://localhost:8000';
export const isLDAP = process.env.REACT_APP_LDAP || false; // set LDAP form
export const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID || process.env.ONESIGNAL_APP_ID || '';

// https://codedthemes.com/demos/admin-templates/datta-able/react/docs/
export const CONFIG = {
    layout: 'vertical', // disable on free version
    subLayout: '', // disable on free version
    collapseMenu: false, // mini-menu
    layoutType: 'default', // 'menu-dark', // disable on free version
    navIconColor: false, // disable on free version
    headerBackColor: 'header-default', // disable on free version
    navBackColor: 'navbar-default', // 'navbar-default', // disable on free version
    navBrandColor: 'brand-default', // 'brand-default', // disable on free version
    navBackImage: false, // disable on free version
    rtlLayout: false, // disable on free version
    navFixedLayout: true, // disable on free version
    headerFixedLayout: false, // disable on free version
    boxLayout: false, // disable on free version
    navDropdownIcon: 'style1', // disable on free version
    navListIcon: 'style6', // disable on free version
    navActiveListColor: 'active-default', // disable on free version
    navListTitleColor: 'title-default', // disable on free version
    navListTitleHide: false, // disable on free version
    configBlock: true, // disable on free version
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // disable on free version
    layout6BackSize: '', // disable on free version
};

// 20 opções de indústria em português brasileiro.
export const industryOptions = [
    { value: 'tecnologia', label: 'Tecnologia' },
    { value: 'saude', label: 'Saúde' },
    { value: 'financas', label: 'Finanças' },
    { value: 'educacao', label: 'Educação' },
    { value: 'entretenimento', label: 'Entretenimento' },
    { value: 'varejo', label: 'Varejo' },
    { value: 'manufatura', label: 'Manufatura' },
    { value: 'agricultura', label: 'Agricultura' },
    { value: 'transporte', label: 'Transporte' },
    { value: 'energia', label: 'Energia' },
    { value: 'construcao', label: 'Construção' },
    { value: 'turismo', label: 'Turismo' },
    { value: 'imobiliario', label: 'Imobiliário' },
    { value: 'comunicacoes', label: 'Comunicações' },
    { value: 'midia', label: 'Mídia' },
    { value: 'servicos', label: 'Serviços' },
    { value: 'logistica', label: 'Logística' },
    { value: 'alimentos_bebidas', label: 'Alimentos e Bebidas' },
    { value: 'quimica', label: 'Química' },
    { value: 'moda', label: 'Moda' }
];

// 6 opções de idioma.
export const languageOptions = [
    { value: 'pt', label: 'Português' },
    { value: 'en', label: 'Inglês' },
    { value: 'es', label: 'Espanhol' },
    { value: 'fr', label: 'Francês' },
    { value: 'de', label: 'Alemão' },
    { value: 'zh', label: 'Chinês' }
];

// Lista completa de países (em português brasileiro)
export const countries = [
    "Afeganistão",
    "África do Sul",
    "Albânia",
    "Alemanha",
    "Andorra",
    "Angola",
    "Antígua e Barbuda",
    "Arábia Saudita",
    "Argélia",
    "Argentina",
    "Armênia",
    "Austrália",
    "Áustria",
    "Azerbaijão",
    "Bahamas",
    "Bangladesh",
    "Barbados",
    "Barein",
    "Bélgica",
    "Belize",
    "Benin",
    "Bielorrússia",
    "Bolívia",
    "Bósnia e Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunei",
    "Bulgária",
    "Burquina Faso",
    "Burundi",
    "Butão",
    "Cabo Verde",
    "Camarões",
    "Camboja",
    "Canadá",
    "Cazaquistão",
    "Chade",
    "Chile",
    "China",
    "Chipre",
    "Colômbia",
    "Comores",
    "Congo (Brazzaville)",
    "Congo (Kinshasa)",
    "Coreia do Norte",
    "Coreia do Sul",
    "Costa do Marfim",
    "Costa Rica",
    "Croácia",
    "Cuba",
    "Dinamarca",
    "Dominica",
    "Egito",
    "El Salvador",
    "Emirados Árabes Unidos",
    "Equador",
    "Eritreia",
    "Eslováquia",
    "Eslovênia",
    "Espanha",
    "Estados Unidos",
    "Estônia",
    "Etiópia",
    "Fiji",
    "Filipinas",
    "Finlândia",
    "França",
    "Gabão",
    "Gâmbia",
    "Gana",
    "Geórgia",
    "Granada",
    "Grécia",
    "Guatemala",
    "Guiana",
    "Guiné",
    "Guiné-Bissau",
    "Haiti",
    "Honduras",
    "Hungria",
    "Iêmen",
    "Ilhas Marshall",
    "Ilhas Salomão",
    "Índia",
    "Indonésia",
    "Irã",
    "Iraque",
    "Irlanda",
    "Islândia",
    "Israel",
    "Itália",
    "Jamaica",
    "Japão",
    "Jordânia",
    "Laos",
    "Lesoto",
    "Letônia",
    "Líbano",
    "Libéria",
    "Líbia",
    "Liechtenstein",
    "Lituânia",
    "Luxemburgo",
    "Madagáscar",
    "Malásia",
    "Malawi",
    "Maldivas",
    "Mali",
    "Malta",
    "Marrocos",
    "Mauritânia",
    "Maurícia",
    "México",
    "Micronésia",
    "Moldávia",
    "Mônaco",
    "Mongólia",
    "Montenegro",
    "Moçambique",
    "Namíbia",
    "Nauru",
    "Nepal",
    "Nicarágua",
    "Nigéria",
    "Noruega",
    "Nova Zelândia",
    "Omã",
    "Paquistão",
    "Palau",
    "Panamá",
    "Papua-Nova Guiné",
    "Paraguai",
    "Peru",
    "Polônia",
    "Portugal",
    "Quênia",
    "Quirguistão",
    "Reino Unido",
    "República Centro-Africana",
    "República Checa",
    "República Dominicana",
    "Romênia",
    "Ruanda",
    "Rússia",
    "Samoa",
    "San Marino",
    "Santa Lúcia",
    "São Cristóvão e Nevis",
    "São Tomé e Príncipe",
    "São Vicente e Granadinas",
    "Seicheles",
    "Senegal",
    "Serra Leoa",
    "Síria",
    "Somália",
    "Sri Lanka",
    "Suazilândia",
    "Sudão",
    "Sudão do Sul",
    "Suécia",
    "Suíça",
    "Suriname",
    "Tailândia",
    "Taiwan",
    "Tajiquistão",
    "Tanzânia",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trindade e Tobago",
    "Tunísia",
    "Turcomenistão",
    "Turquia",
    "Tuvalu",
    "Ucrânia",
    "Uganda",
    "Uruguai",
    "Uzbequistão",
    "Vanuatu",
    "Vaticano",
    "Venezuela",
    "Vietnã",
    "Zâmbia",
    "Zimbábue"
];
